


import { Component, Vue, Ref } from 'vue-property-decorator';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';

const CANVAS_ID_ATTRIBUTE = 'notFoundArtBoard';

type TPageNotFoundMouseCoords = {
  x: number;
  y: number;
}

@Component({
  components: {EwButton}
})
export default class PageNotFound extends Vue {

  @Ref(CANVAS_ID_ATTRIBUTE) canvasElementRef: HTMLCanvasElement;

  public canvasRefName: string = CANVAS_ID_ATTRIBUTE;
  public ctx: CanvasRenderingContext2D;
  public isMouseDown: boolean = false;
  public mouseX: number = 0;
  public mouseY: number = 0;
  public isCanvasDirty: boolean = false;

  public get currentMouse(): TPageNotFoundMouseCoords {
    const rect = this.canvasElementRef.getBoundingClientRect();
    return {
      x: this.mouseX - rect.left,
      y: this.mouseY - rect.top,
    };
  }

  public get isCanvasActionsVisible(): boolean {
    return this.isCanvasDirty;
  }

  public mounted(): void {
    this.init();
  }

  public init(): void {
    if (!this.canvasElementRef) {
      window.setTimeout(this.init, 250);
      return;
    }
    this.canvasElementRef.width = window.innerWidth;
    this.canvasElementRef.height = window.innerHeight;
    this.ctx = this.canvasElementRef.getContext('2d');
    this.ctx.translate(0.5, 0.5);
    this.ctx.imageSmoothingEnabled = false;
    this.draw();
  }

  public draw(): void {
    if (this.isMouseDown && this.ctx) {
      this.cls();
      this.ctx.lineTo(this.currentMouse.x, this.currentMouse.y);
      this.ctx.strokeStyle = '#fff';
      this.ctx.lineWidth = 2;
      this.ctx.stroke();
      this.isCanvasDirty = true;
    }
    window.requestAnimationFrame(this.draw);
  }

  public cls(): void {
    this.ctx.clearRect(0, 0, window.innerWidth, window.innerWidth);
  }

  public handleMouseDown(event: PointerEvent): void {
    this.isMouseDown = true;
    this.setMouseCoords(event);
    if (this.ctx) {
      this.ctx.moveTo(this.currentMouse.x, this.currentMouse.y);
    }
  }

  public handleMouseUp(): void {
    this.isMouseDown = false;
  }

  public handleMouseMove(event: PointerEvent): void {
    this.setMouseCoords(event);
    this.draw();
  }

  public setMouseCoords(event: PointerEvent): void {
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;
  }

  public onClearClick(): void {
    this.cls();
    this.ctx.beginPath();
    this.isCanvasDirty = false;
  }
}
